const IconWavesDown = () => {
  return (
    <svg 
      id="wavesDOWN" 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 585.69 562.32"
      className="w-[300px] absolute top-[80px] -left-[55px] -z-20"
    >
      <defs>
        <style>
          {/* .cls-1_wavesdown {
            fill: #fff;
            stroke: #231f20;
            stroke-linecap: round;
            stroke-linejoin: round;
          } */}
        </style>
      </defs>
      <g>
        <path className="cls-1_wavesdown" d="m253.78,252.05c0-24.73,19.07-56.14,45.85-67.53,10.54-4.48,20.8,8.47,13.87,17.54-11.76,15.39-16.02,36.57-15.79,50"/>
        <path className="cls-1_wavesdown" d="m258.06,252.05c0-21.12,19.41-53.16,34.65-54.95,5.24-.61,11.89,4.67,7.47,14.27-2.38,5.16-7.18,15.57-7.18,40.67"/>
        <path className="cls-1_wavesdown" d="m264.08,252.05c0-19.57,11.08-34.33,16.41-37.4,5.76-3.32,10.35,2.29,8.47,9.33-1.89,7.04-2.24,28.07-2.24,28.07"/>
        <path className="cls-1_wavesdown" d="m270.33,252.05s.11-9.1,1.43-11.63c2.83-5.43,11.19-5.58,10.04,4.15l-.89,7.48"/>
      </g>
      <g>
        <path className="cls-1_wavesdown" d="m154.85,252.05c0-91.38,63.32-153.87,112.22-158.95,10.88-1.13,14.46,11.1,6.02,14.9-27.28,12.27-76.08,54.66-76.08,144.05"/>
        <path className="cls-1_wavesdown" d="m158.97,252.05c0-87.24,58.11-129.7,67.39-130.74,7.7-.86,9.98,5.73,5.6,9.97-5.81,5.62-39.23,46.67-39.23,120.77"/>
        <path className="cls-1_wavesdown" d="m163.41,252.05c0-54.21,22.68-85.06,29.45-90.8,6.77-5.74,12.18.99,10.18,5.71-2.01,4.72-14.26,30.57-14.26,85.09"/>
        <path className="cls-1_wavesdown" d="m167.78,252.05c0-18.03,3.55-31.99,5.38-35.62,1.82-3.63,9.52-3.78,8.07,4.48-1.45,8.26-.83,18.64-.83,31.14"/>
      </g>
      <path className="cls-1_wavesdown" d="m207.34,252.05c0-64.85,43.47-106.45,76.33-112.81,7.66-1.48,14.89,12.42,5.89,17.3-17.99,9.75-44.58,32.07-44.58,95.51m-34.26,0c0-61.91,38.95-92.05,45.26-92.79,5.24-.61,10.28,6.8,5.08,13.1-6.31,7.64-19.88,32.17-19.88,79.69m-27.12,0c0-35.94,14.45-56.3,19.77-59.37,5.76-3.32,11.03.5,9.14,7.54-1.89,7.04-5.38,18.84-5.38,51.83m-19.97,0c0-12.8,2.13-20.53,3.45-23.06,2.83-5.43,8.52-4.07,8.02,5.72l-.88,17.34"/>
      <g>
        <path className="cls-1_wavesdown" d="m425.62,252.05c0,24.73-19.07,56.14-45.85,67.53-10.54,4.48-20.8-8.47-13.87-17.54,11.76-15.39,16.02-36.57,15.79-50"/>
        <path className="cls-1_wavesdown" d="m421.34,252.05c0,21.12-19.41,53.16-34.65,54.95-5.24.61-11.89-4.67-7.47-14.27,2.38-5.16,7.18-15.57,7.18-40.67"/>
        <path className="cls-1_wavesdown" d="m415.32,252.05c0,19.57-11.08,34.33-16.41,37.4-5.76,3.32-10.35-2.29-8.47-9.33,1.89-7.04,2.24-28.07,2.24-28.07"/>
        <path className="cls-1_wavesdown" d="m409.07,252.05s-.11,9.1-1.43,11.63c-2.83,5.43-11.19,5.58-10.04-4.15l.89-7.48"/>
      </g>
      <g>
        <path className="cls-1_wavesdown" d="m524.55,252.05c0,91.38-63.32,153.87-112.22,158.95-10.88,1.13-14.46-11.1-6.02-14.9,27.28-12.27,76.08-54.66,76.08-144.05"/>
        <path className="cls-1_wavesdown" d="m520.43,252.05c0,87.24-58.11,129.7-67.39,130.74-7.7.86-9.98-5.73-5.6-9.97,5.81-5.62,39.23-46.67,39.23-120.77"/>
        <path className="cls-1_wavesdown" d="m515.99,252.05c0,54.21-22.68,85.06-29.45,90.8-6.77,5.74-12.18-.99-10.18-5.71,2.01-4.72,14.26-30.57,14.26-85.09"/>
        <path className="cls-1_wavesdown" d="m511.61,252.05c0,18.03-3.55,31.99-5.38,35.62-1.82,3.63-9.52,3.78-8.07-4.48,1.45-8.26.83-18.64.83-31.14"/>
      </g>
      <path className="cls-1_wavesdown" d="m472.06,252.05c0,64.85-43.47,106.45-76.33,112.81-7.66,1.48-14.89-12.42-5.89-17.3,17.99-9.75,44.58-32.07,44.58-95.51m34.26,0c0,61.91-38.95,92.05-45.26,92.79-5.24.61-10.28-6.8-5.08-13.1,6.31-7.64,19.88-32.17,19.88-79.69m27.12,0c0,35.94-14.45,56.3-19.77,59.37-5.76,3.32-11.03-.5-9.14-7.54,1.89-7.04,5.38-18.84,5.38-51.83m19.97,0c0,12.8-2.13,20.53-3.45,23.06-2.83,5.43-8.52,4.07-8.02-5.72l.88-17.34"/>
    </svg>
  );
};

export default IconWavesDown;