const IconWavesUp = () => {
  return (
    <svg 
      id="wavesUP" 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 585.69 562.32"
      className="w-[300px] absolute top-[80px] -left-[55px]"
    >
      <defs>
        <style>
          {/* .cls-1-wavesup {
            fill: #fff;
            stroke: #231f20;
            stroke-linecap: round;
            stroke-linejoin: round;
          } */}
        </style>
      </defs>
      <g>
        <path className="cls-1-wavesup" d="m297.7,252.05c-.23,13.42,4.03,34.6,15.79,50,6.93,9.07-3.33,22.02-13.87,17.54-26.77-11.39-45.85-42.8-45.85-67.53"/>
        <path className="cls-1-wavesup" d="m292.99,252.05c0,25.1,4.8,35.51,7.18,40.67,4.42,9.6-2.23,14.89-7.47,14.27-15.23-1.79-34.65-33.83-34.65-54.95"/>
        <path className="cls-1-wavesup" d="m286.71,252.05s.36,21.03,2.24,28.07c1.89,7.04-2.71,12.65-8.47,9.33-5.32-3.07-16.41-17.83-16.41-37.4"/>
        <path className="cls-1-wavesup" d="m280.92,252.05l.89,7.48c1.15,9.73-7.22,9.58-10.04,4.15-1.32-2.53-1.43-11.63-1.43-11.63"/>
      </g>
      <g>
        <path className="cls-1-wavesup" d="m197.01,252.05c0,89.4,48.8,131.78,76.08,144.05,8.44,3.8,4.86,16.03-6.02,14.9-48.9-5.09-112.22-67.58-112.22-158.95"/>
        <path className="cls-1-wavesup" d="m192.73,252.05c0,74.1,33.42,115.15,39.23,120.77,4.38,4.24,2.1,10.83-5.6,9.97-9.28-1.04-67.39-43.5-67.39-130.74"/>
        <path className="cls-1-wavesup" d="m188.77,252.05c0,54.52,12.25,80.37,14.26,85.09,2.01,4.72-3.41,11.45-10.18,5.71-6.77-5.74-29.45-36.59-29.45-90.8"/>
        <path className="cls-1-wavesup" d="m180.4,252.05c0,12.49-.61,22.88.83,31.14,1.45,8.26-6.24,8.11-8.07,4.48-1.82-3.63-5.38-17.59-5.38-35.62"/>
      </g>
      <path className="cls-1-wavesup" d="m244.98,252.05c0,63.45,26.59,85.77,44.58,95.51,9,4.88,1.77,18.78-5.89,17.3-32.86-6.37-76.33-47.96-76.33-112.81m33.84,0c0,47.52,13.57,72.05,19.88,79.69,5.2,6.3.16,13.71-5.08,13.1-6.31-.74-45.26-30.87-45.26-92.79m26.86,0c0,32.99,3.5,44.79,5.38,51.83,1.89,7.04-3.38,10.86-9.14,7.54-5.32-3.07-19.77-23.43-19.77-59.37m14.15,0l.88,17.34c.5,9.79-5.2,11.14-8.02,5.72-1.32-2.54-3.45-10.27-3.45-23.06"/>
      <g>
        <path className="cls-1-wavesup" d="m381.7,252.05c.23-13.42-4.03-34.6-15.79-50-6.93-9.07,3.33-22.02,13.87-17.54,26.77,11.39,45.85,42.8,45.85,67.53"/>
        <path className="cls-1-wavesup" d="m386.4,252.05c0-25.1-4.8-35.51-7.18-40.67-4.42-9.6,2.23-14.89,7.47-14.27,15.23,1.79,34.65,33.83,34.65,54.95"/>
        <path className="cls-1-wavesup" d="m392.69,252.05s-.36-21.03-2.24-28.07c-1.89-7.04,2.71-12.65,8.47-9.33,5.32,3.07,16.41,17.83,16.41,37.4"/>
        <path className="cls-1-wavesup" d="m398.48,252.05l-.89-7.48c-1.15-9.73,7.22-9.58,10.04-4.15,1.32,2.53,1.43,11.63,1.43,11.63"/>
      </g>
      <g>
        <path className="cls-1-wavesup" d="m482.39,252.05c0-89.4-48.8-131.78-76.08-144.05-8.44-3.8-4.86-16.03,6.02-14.9,48.9,5.09,112.22,67.58,112.22,158.95"/>
        <path className="cls-1-wavesup" d="m486.67,252.05c0-74.1-33.42-115.15-39.23-120.77-4.38-4.24-2.1-10.83,5.6-9.97,9.28,1.04,67.39,43.5,67.39,130.74"/>
        <path className="cls-1-wavesup" d="m490.63,252.05c0-54.52-12.25-80.37-14.26-85.09-2.01-4.72,3.41-11.45,10.18-5.71,6.77,5.74,29.45,36.59,29.45,90.8"/>
        <path className="cls-1-wavesup" d="m499,252.05c0-12.49.61-22.88-.83-31.14-1.45-8.26,6.24-8.11,8.07-4.48,1.82,3.63,5.38,17.59,5.38,35.62"/>
      </g>
      <path className="cls-1-wavesup" d="m434.42,252.05c0-63.45-26.59-85.77-44.58-95.51-9-4.88-1.77-18.78,5.89-17.3,32.86,6.37,76.33,47.96,76.33,112.81m-33.84,0c0-47.52-13.57-72.05-19.88-79.69-5.2-6.3-.16-13.71,5.08-13.1,6.31.74,45.26,30.87,45.26,92.79m-26.86,0c0-32.99-3.5-44.79-5.38-51.83-1.89-7.04,3.38-10.86,9.14-7.54,5.32,3.07,19.77,23.43,19.77,59.37m-14.15,0l-.88-17.34c-.5-9.79,5.2-11.14,8.02-5.72,1.32,2.54,3.45,10.27,3.45,23.06"/>
    </svg>
  );
};

export default IconWavesUp;